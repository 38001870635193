import { useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import {
  Button,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  UncontrolledButtonDropdown,
} from 'reactstrap'
import { useResource } from 'rest-hooks'
import SitesResource from '../../../resources/site'
import SimpleModal from '../Shared/Components/SimpleModal'

export default function AlgorithmsList({ data = [], onDelete }) {
  let [nestedModal, setNestedModal] = useState({
    isOpen: false,
    confirmAction: () => {},
  })
  const hideModal = () => {
    setNestedModal({ isOpen: false })
  }
  const deleteAlgorithm = id => {
    setNestedModal({
      isOpen: true,
      confirmAction: () => {
        hideModal()
        onDelete(id)
      },
    })
  }

  let { brandId } = useParams()
  const { results: sites } = useResource(SitesResource.list(), {
    customerid: brandId,
  })

  const getSiteByEndpointId = id => {
    let endpoint
    let site = sites.find(
      site => (endpoint = site.endpoints.find(ep => ep.id === id)),
    )
    return { site, endpoint }
  }

  const items = data.map(algorithm => (
    <ListGroupItem key={algorithm.id}>
      <div className="widget-content p-0">
        <div className="widget-content-wrapper">
          <div className="widget-content-left">
            <div className="widget-heading">{algorithm.name}</div>
            <div className="widget-subheading d-none d-lg-block">
              {algorithm.description}
            </div>
          </div>
          <div
            className="widget-content-right d-none d-md-block"
            style={{ fontSize: '0.8rem', fontWeight: '500' }}
          >
            {!algorithm.endpoints?.length ? (
              'No Linked Endpoints'
            ) : (
              <UncontrolledButtonDropdown>
                <DropdownToggle caret outline className="m-0 p-0" color="link">
                  Linked Endpoints: {algorithm.endpoints?.length}
                </DropdownToggle>
                <DropdownMenu>
                  {algorithm.endpoints.map(ep => {
                    let { site, endpoint } = getSiteByEndpointId(ep.id)

                    return !site || !endpoint ? null : (
                      <DropdownItem key={ep.id}>
                        <Link
                          to={`sites/${site.id}`}
                          style={{
                            color: '#000',
                            textDecoration: 'none',
                          }}
                        >
                          {site.name + ' - ' + endpoint.name}
                        </Link>
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            )}
            <div className="widget-heading">
              {algorithm.kpiGroups.reduce(
                (acc, group) => acc + group?.kpIs?.length,
                0,
              )}
              {" KPI's"}
            </div>
          </div>
          <div className="ml-md-3 widget-content-right ml-sm-auto">
            <Link to={`algorithms/${algorithm.id}`} className="mr-2">
              <Button outline size="lg" color="info">
                Edit
              </Button>
            </Link>
            <Button
              outline
              size="lg"
              color="danger"
              name={`delete_${algorithm.id}`}
              id={`delete_${algorithm.id}`}
              onClick={() => deleteAlgorithm(algorithm.id)}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </ListGroupItem>
  ))
  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Container fluid>
          <ListGroup flush>{items}</ListGroup>
          <Link to={`algorithms/new`} className="mr-2">
            <Button
              className="mt-4 mr-2 btn-icon"
              size="lg"
              color="primary"
              name="_addEndpoint"
            >
              Add Algorithm
            </Button>
          </Link>
          <SimpleModal
            toggle={hideModal}
            body="Are you sure you want to delete this algorithm?"
            confirmLabel="Delete"
            {...nestedModal}
          />
        </Container>
      </CSSTransitionGroup>
    </>
  )
}
